.fileUploader {
  width: 100%;
}

.fileContainer {
  background: #fff;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin: 10px auto;
  transition: all 0.3s ease-in;
}

.fileContainer input {
  opacity: 0;
  position: absolute;
  z-index: -1;
  width: 0;
  height: 0;
}

.fileContainer p {
  font-size: 12px;
  margin: 8px 0 4px;
}

.fileContainer .errorsContainer {
  max-width: 300px;
  font-size: 12px;
  color: red;
  text-align: left;
}

.fileContainer .chooseFileButton {
  width: 100px;
  height: 100px;
  background: rgba(90, 132, 235, 0.2);
  border-radius: 5px;
  font-weight: 300;
  font-size: 14px;
  margin: 10px 0;
  transition: all 0.2s ease-in;
  cursor: pointer;
  outline: none;
  border: 1px dashed rgba(90, 132, 235, 1);
}

.fileContainer .chooseFileButton:hover {
  background: rgba(90, 132, 235, 1);
}

.fileContainer .chooseFileButton:hover .chooseFileButtonImg:after {
  background-color: #fff;
}
.fileContainer .chooseFileButton:hover .chooseFileButtonImg:before {
  background-color: #fff;
}

.fileContainer .uploadFilesButton {
  padding: 5px 43px;
  background: transparent;
  border-radius: 5px;
  color: rgba(90, 132, 235, 0.3);
  margin: 10px 0;
  transition: all 0.2s ease-in;
  cursor: pointer;
  border: 1px dashed rgba(90, 132, 235, 1);

  display: flex;
  align-content: center;
  justify-content: center;
}

.fileContainer .chooseFileButton .chooseFileButtonImg {
  width: 40%;
  height: 40%;
  position: relative;
  margin: 0 auto;
}

.fileContainer .chooseFileButton .chooseFileButtonImg:before {
  content: "";
  position: absolute;
  background-color: rgba(90, 132, 235, 1);
}

.fileContainer .chooseFileButton .chooseFileButtonImg:after {
  content: "";
  position: absolute;
  background-color: rgba(90, 132, 235, 1);
}

.fileContainer .chooseFileButton .chooseFileButtonImg:before {
  top: 0;
  left: 50%;
  width: 4px;
  height: 100%;
  margin-left: -2px;
}

.fileContainer .chooseFileButton .chooseFileButtonImg:after {
  top: 50%;
  left: 0;
  width: 100%;
  height: 4px;
  margin-top: -2px;
}

.fileContainer .uploadFilesButton:hover {
  background: #3f4257;
  color: #fff;
}

.fileContainer .uploadIcon {
  width: 50px;
  height: 50px;
}

.fileContainer .uploadPicturesWrapper {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center; */
  width: 100%;
}

.fileContainer .uploadPictureContainer {
  width: 100px;
  height: 100px;
  margin: 10px;
  padding: 0;
  background: #edf2f6;
  display: flex;
  align-items: normal;
  justify-content: center;
  box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.1);
  border: 1px solid #d0dbe4;
  position: relative;
  border-radius: 5px;
}

.fileContainer .uploadPictureContainer img.uploadPicture {
  object-fit: cover;
  border-radius: 5px;
  width: inherit;
  height: inherit;
}

.fileContainer .uploadPictureContainer img.disabled {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  filter: grayscale(100%);
}

.fileContainer .uploadPictureContainer  .deleteImage {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #fff;
  background: rgba(90, 132, 235, 1);
  border-radius: 50%;
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 1;
}

.fileContainer .uploadPictureContainer  .deleteImage > img {
  height: 11px;
  width: 11px;
  fill: #fff;
}

.fileContainer .uploadPictureContainer  .deleteImage > img > svg {
  fill: #fff;
}

.flipMove {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}
